// these were taken from app/components/siteFrame/siteFrame.scss
// not sure if these can be imported into that stylesheet, so they are duplicated there
$frameWidth: calc(1600px - 3vw);
$color-FirstConnect: #022969;
$color-Links: #285A8F;
$color-LightHighlight: #E3EFF6;
$color-Box: #ECECF0;
$color-FirstLegal: #5C315E;
$color-ErrorDark: #AC172B;
$color-ErrorLight: #F6E7E9;
$color-SuccessDark: #799900;
$color-legalAlt-light: #49726A;
$color-legalAlt-dark: #E0F2F1;
$color-SuccessLight: #F1F4E5;
$color-000000: #000000;
$font-size:(
    "h1": 2em,
    "h2": 1.5em,
    "h3": 1.2em,
    "body": 1em,
    "small": 0.8em
);