/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;700&display=swap');
@import 'https://fonts.googleapis.com/icon?family=Material+Icons'; //@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400&display=swap');
@import 'theme';

html {
	height: 100vh;
}

p, body, a {
	font-family: mat.font-family($typography);
}

body {
	background-color: rgb(240, 240, 240);
	color: #212121;
	// height: 100vh;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
}

app-root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

a {
	color: $color-Links;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
h1, h2, h3, h4, h5 {
	font-family: Lato, sans-serif;
	font-weight: 400;
}

h1 {
	font-size: 1.75em;
}
h2 {
	font-size: 1.2em;
	border-bottom: 1px solid #ddd;
	padding-bottom: 4px;
}
p {

}

sup {
	font-size: .6em;
}

.mat-dialog-container {
	background: #FFFFFF;

	.mat-dialog-title {
		font-size: 2em;
		color: $color-FirstConnect;
	}

	.mat-dialog-content {
		display: flex;
		flex-flow: column;
	}

	.mat-dialog-actions {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
	}

}

.info-block {
	background: rgba(0,0,0,.05);
	border-radius: 10px;
	padding: 1em 1em 1em 8em;
	margin: 2em 0;
	color: black;
	line-height: 2em;
	font-size: 0.9em;
	letter-spacing: 1px;
	position: relative;

	mat-icon {
		position: absolute;
		font-size: 6em;
		color: $color-FirstConnect;
		top: 0;
		left: 0.1em;
	}

	&.notice {

	}
	&.warning {
		background: #f3c61110;
		mat-icon {
			color: #f3c61140;
		}
	}
	&.error {
		background: #a04f4f40;
		mat-icon {
			color: #a04f4f80;
		}
	}
}


/* It'd be swell if we could move this to reflex-core */
resource-list-pane {
	.cdk-column-select {
		flex: 0 1 40px;
	}
	.cdk-column-View {
		flex: 0 1 40px;

		.mat-flat-button {
			padding: 0;
			min-width: inherit;

			mat-icon {
				color: #ccc;
				fill: #ccc;
			}

			&:hover {
				mat-icon {
					color: #084da4;
					fill: #084da4;
				}
			}
		}
	}
	.cdk-column-Tip {
		flex: 0 1 40px;

		mat-icon {
			color: #ccc;
			fill: #ccc;
		}

		&:hover {
			mat-icon {
				color: #888;
				fill: #888;
			}
		}
	}
	.cdk-column-Remove {
		flex: 0 1 40px;

		.mat-flat-button {
			padding: 0;
			min-width: inherit;

			mat-icon {
				color: #a04f4f;
				fill: #a04f4f;
			}

			&:hover {
				mat-icon {
					color: #863535;
					fill: #863535;
				}
			}
		}
	}
	.cdk-column-Type {
		flex: 0 1 150px;
	}
	.cdk-column-Status {
		flex: 0 1 110px;
	}
	.cdk-column-PublishDate {
		flex: 0 1 150px;
	}
}



*::-webkit-scrollbar {
	width: 0.9em;
}

*::-webkit-scrollbar-track {
	background-color: white;
}

*::-webkit-scrollbar-thumb {
	background-color: $color-FirstConnect;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	margin: 1px;
	border: 0.1em solid white;
}

.mat-progress-bar {
	height: 6px;

	.mat-progress-bar-background {
		background-color: linear-gradient(90deg, $color-FirstConnect, #0d3759) !important;
	}
	.mat-progress-bar-buffer {
		background-color: linear-gradient(90deg, $color-FirstConnect, #0d3759) !important;
	}
	.mat-progress-bar-fill::after {
		background: linear-gradient(90deg, $color-FirstConnect, #4db6ac, #234d95);
	}
}

.colorDot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 10px;
	background-color: white;
	border: 1px solid white;
	border-radius: 20px;
}

.row {
	display: flex;
	flex-flow: row wrap;

	.column {
		flex-basis: 600px;
		flex-grow: 1;
		padding: 0 2vw;
		min-width: 600px;
		display: flex;
		flex-flow: column;

		&:first-child {
			border-right: 1px solid #d6d6d7;
		}
	}
}

//This is to stop the freshworks chat from loading on the right side of the screen
// and push everything left and then back to right as it changes the width after loading. 
// This only shows up when loading or reloading the page
// This doesn't seem to effect it after it has loaded and adjusted it self
#fc_frame {
	width: 0;
}

.mat-tooltip {
	font-size: 0.9em !important;
	background: rgba(0,0,0,.8) !important;
	border-color: rgba(255,255,255,.1) !important;
}