@use '~@angular/material' as mat;
@import "~@angular/material/theming";
@include mat.core();
@import 'variables';
/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e5eaf2,
    100 : #bdcadf,
    200 : #91a6ca,
    300 : #6582b5,
    400 : #4468a5,
    500 : $color-FirstConnect,
    600 : $color-FirstConnect,
    700 : $color-FirstConnect,
    800 : $color-FirstConnect,
    900 : $color-FirstConnect,
    A100 : #9ab1ff,
    A200 : #678aff,
    A400 : #3462ff,
    A700 : #1a4fff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #E8F5E9,
    100 : #C8E6C9,
    200 : #A5D6A7,
    300 : #81C784,
    400 : #66BB6A,
    500 : #4CAF50,
    600 : #43A047,
    700 : #388E3C,
    800 : #2E7D32,
    900 : #1B5E20,
    A100 : #B9F6CA,
    A200 : #69F0AE,
    A400 : #00E676,
    A700 : #00C853,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #ffecec,
    100 : #ffcfcf,
    200 : #ffb0b0,
    300 : #ff9090,
    400 : #ff7878,
    500 : #ff6060,
    600 : #ff5858,
    700 : #ff4e4e,
    800 : #ff4444,
    900 : #ff3333,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffdede,
    A700 : #ffc4c4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$typography: mat.define-typography-config(
  $font-family: 'Barlow',
);

$base-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($md-primary, 500, 700, 900),
      accent: mat.define-palette($md-accent, 500, 700, 900),
      warn: mat.define-palette($md-warn, 500, 700, 900),
    ),
    typography: $typography
  )
);

$base-background-color: #FFFFFF;
$background: map-get($base-theme, background);
$background: map_merge($background, (background: $base-background-color));
$dark-theme: map_merge($base-theme, (background: $background));


@include mat.all-component-themes($base-theme);
